import React, { useEffect } from 'react';
import { useAuth } from '@chordcommerce/gatsby-theme-autonomy';
import { navigate } from 'gatsby';

const Authenticate: React.FC = () => {
  const { handleCallback } = useAuth();

  useEffect(() => {
    handleCallback()
      .then(() => navigate('/account'))
      .catch(e => {
        console.error(
          'could not auth.handleCallback, redirecting to the home page',
          e
        );
        navigate('/');
      });
    // Tracking handleCallback here will cause this to double fire and throw and
    // error.
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return null;
};

export default Authenticate;
